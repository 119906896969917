<template>
  <div>
    <b-card-header class="mt-n2 ">
       <div class="d-flex align-items-center ">

                <div>
                  <h3 class="mb-0 font-weight-bolder "> Cost </h3>
                </div>
                </div>

                <div class="ml-auto ">
                          <b-button-group class="bg-white">
                             <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                                    Service Charge
                             </b-button>
                             <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary">
                                    Staff Payment
                             </b-button>
                          </b-button-group>

                </div>
      <b-card-text class="font-medium-5 mb-0 ml-2">

        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>
  <div>
    <b-row class="mb-0">

    <b-col cols="12">
      <b-table
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :items="items"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="text-right custom_align mobile_table_css"
          hover
          responsive
         >

      </b-table>
    </b-col>
    </b-row>
  </div>
  </div>
</template>

<script>
import LineChart from '../chartcomponents/linechart.vue'
import {BTable, BButtonGroup, BAvatar, BBadge, BAlert, BButton, BCard, BCol, BLink, BRow, BCardHeader, BCardText }from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
      BTable,
    LineChart,
    BCardHeader,
    BCardText,
    BAlert,
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BLink,
    BRow,
    BButtonGroup,
    BCardText
  },

data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [

        {key: 'location', label: 'LOCATION', sortable: true},
        {key: 'cost', label: 'COST', sortable: true},

      ],
      items: [
        {
          'location': 'Tottenham Hale Medical Practice',
          'cost': '£1,150.00',
        },
        {
          'location': 'London Road Medical Practice',
          'cost': '£800.00',

        },
        {
          'location': 'Merton Medical Practice',
          'cost': '£360.00',

        },
        {
          'location': 'The Birmingham Free School',
          'cost': '£1,200.00',

        },
        {
          'location': 'St. Louis School',
          'cost': '£1,500.00',

        },
        {
          'location': 'The London General Practice',
          'cost': '£460.00',

        },
        {
          'location': 'Total Care Physicians',
          'cost': '£890.00',

        },
        {
          'location': 'Central Medical Practice in Glasgow',
          'cost': '£290.00',

        },
        {
          'location': 'Tottenham Hale Medical Practice',
          'cost': '£580.00',

        },

      ],
    }
  },
  methods: {

  },
directives: {
  Ripple,
},

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
}
</script>

<style scoped>
/*//custom align for locations table to meet the collapse alignments*/
.custom_align >>> td:first-child {
  text-align: left;
}

.custom_align >>> th:first-child {
  text-align: left;
}
.custom_align >>> td {
  padding-right: 10px;
}
.custom_align >>> th {
  padding-right: 20px;
}
</style>


<script>
  import { Line } from 'vue-chartjs'

  export default {
    extends: Line,
    data () {
      return {
        chartData: {
          labels: [ "Mon",	"Tue",	"Wed",	"Thu",	"Fri",	"Sat",	"wer"],
          datasets: [
            {
              label: 'Salaried Staff',
              data: [5,	18,	15,	18,	20,	23,	32],
              fill: false,
              borderColor: '#1B9AAA',
              backgroundColor: '#1B9A33',
              borderWidth: 1
            },
              {
              label: 'Locumes Shifts',
              data: [5, 4,9,6,12,10,15],
              fill: false,
              borderColor: '#CCE6F4',
              backgroundColor: '#CCE6F4',
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: true
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    }
  }
</script>
<template>
    <div class="home">

        <!-- Header Bar -->
             <div>
                 <b-card >
                    <b-row class=" mt-n2 ml-0">

          <b-col cols="12" class="pt-0 pb-0">
            <div class="row">

               <!-- 01 Total staff -->

                <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-info" rounded>
                        <feather-icon icon="UsersIcon" size="18"/>
                    </b-avatar>

                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">868</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-success font-weight-bold small">
                                 <feather-icon icon="ArrowUpIcon" />
                                 8%
                            </small>
                         </li>
                      </ul>
                           <small>Total Staff</small>
                    </div>
                </div>

               <!-- 02 Salaried Staff -->

                 <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar text="S" variant="light-success" rounded  size="35" />
      
                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">648</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-danger font-weight-bold small">
                                 <feather-icon  icon="ArrowDownIcon" />
                                 3%
                            </small>
                         </li>
                      </ul>
                           <small>Salaried Staff</small>
                    </div>
                </div>

                <!-- 03 Locumes -->


                 <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar text="L" variant="light-primary" rounded  size="35" />

                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">220</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-success font-weight-bold small">
                                 <feather-icon icon="ArrowUpIcon" />
                                 12%
                            </small>
                         </li>
                      </ul>
                           <small>Locumes</small>
                    </div>
                </div>
                


                <!-- 04 Total Application -->


                 <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-warning" rounded >
                        <feather-icon icon="UserIcon" size="18" />
                    </b-avatar>
      
                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">89</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-danger font-weight-bold small">
                                 <feather-icon icon="ArrowDownIcon" />
                                 2%
                            </small>
                         </li>
                      </ul>
                           <small>Total Applications</small>
                    </div>
                </div>
                


                 <!-- 05 Accept Applications -->
                

                 <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-success" rounded >
                        <feather-icon icon="CheckSquareIcon" size="18" />
                    </b-avatar>
      
                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">23</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-success font-weight-bold small">
                                 <feather-icon icon="ArrowUpIcon" />
                                 4%
                            </small>
                         </li>
                      </ul>
                           <small>Accept Applications</small>
                    </div>
                </div>
                
                 <!-- 06 Reject Applications -->

                 <div class="d-flex align-items-center mr-5 mt-2">

                    
                    <b-avatar variant="light-danger" rounded >
                        <feather-icon icon="XSquareIcon" size="18" />
                    </b-avatar>

                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">66</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-danger font-weight-bold small">
                                 <feather-icon icon="ArrowDownIcon" />
                                 1.5%
                            </small>
                         </li>
                      </ul>
                           <small>Rejected Applications</small>
                    </div>
                </div>
                



            </div>
             
          </b-col>

      </b-row>
      </b-card>
             </div>

             
              <!-- Charts -->

             <div>
               <b-row class="match-height">
                 <b-col lg="4">
                     <b-card>
                      <BankAvailability/>
                     </b-card>
                 </b-col>
                 <b-col lg="4">
                     <b-card>
                      <BankType/>
                     </b-card>
                 </b-col>
                   <b-col lg="4">
                       <b-card>
                      <Applications/>
                       </b-card>
                 </b-col>
               </b-row>
             </div>

</div>
</template>

<script>
import BankAvailability from './bankavailability'
import BankType from './banktype'
import Applications from './applications'
import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow} from 'bootstrap-vue'

export default {
  components: {
    BankAvailability,
    BankType,
    Applications,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
  },
  data() {
    return { 
    }
  },
}
</script>

<style scoped>

</style>

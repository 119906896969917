<template>
  <b-card>
    <b-card-header class="mt-n1">
      <div class="d-flex align-items-center">
        <div>
          <h3 class="mb-0 font-weight-bolder">Applications</h3>
        </div>
      </div>

      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <!-- echart -->
      <div>
        <app-echart-doughnut :series="series" />
      </div>
    </b-card-body>
    <div>
      <div class="d-flex justify-content-center mt-n4">
        <div class="p-2">
          <div class="d-flex align-items-center">
            <b-avatar variant="success" rounded size="10" class="mt-n1">
              <feather-icon icon="CheckIcon" size="1" />
            </b-avatar>

            <div class="ml-1">
              <ul class="list-inline mb-0">
                <h4 class="mb-0 font-weight-bolder">23</h4>
              </ul>
              <small>Accepted</small>
            </div>
          </div>
        </div>
        <div class="p-2">
          <div class="d-flex align-items-center">
            <b-avatar variant="danger" rounded size="10" class="mt-n1">
              <feather-icon icon="CheckIcon" size="1" />
            </b-avatar>

            <div class="ml-1">
              <ul class="list-inline mb-0">
                <h4 class="mb-0 font-weight-bolder">66</h4>
              </ul>
              <small>Rejected</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BCardText,
  BAlert,
  BAvatar,
} from "bootstrap-vue";
import AppEchartDoughnut from "./AppEchartDoughnut.vue";

export default {
  components: {
    BAlert,
    BCard,
    BAvatar,
    BCardHeader,
    BRow,
    BCol,
    BCardText,
    AppEchartDoughnut,
  },
  data() {
    return {
      series: [
        {
          name: "Visit source",
          type: "pie",
          radius: ["40%", "60%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: true,
          },
          data: [
            { value: 23, name: "Accepted" },
            { value: 66, name: "Rejected" },
          ],
          color: ["#00C49A", "#FF4848"],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.card-body {
  position: relative;
}
</style>

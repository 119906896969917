<template>
  <div class="Home">
      <b-row class=" ">
               <!-- Left side -->

        <b-col cols="12"  xl="4" class="pt-0 pb-2 pt-xl-2 pb-xl-0 ">
            <div class="row">

               <!-- 01 All Programmes -->

                       <b-col cols="12" md="6" class="mb-1">
                            <b-form-group>
                                <v-select
                                    class="bg-white"
                                    placeholder="All Programmes"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title1"
                                    :options="option1"
                                />
                            </b-form-group>
                        </b-col>

                <!-- 02 All Locations -->

                        <b-col cols="12" md="6">
                             <b-form-group>
                                <v-select
                                   class="bg-white"
                                   placeholder="All Locations"
                                   :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title2"
                                   :options="option2"
                               />
                              </b-form-group>
                        </b-col>          
              
            </div>
        </b-col>



        <b-col cols="12"  xl="3" class="pt-0 pb-2 pt-xl-2 pb-xl-0">
            <b-row>
            </b-row>
        </b-col>

                <!-- Right side Downloading button -->

        <b-col cols="12"  xl="5" class="pt-0 pb-2 pt-xl-2 pb-xl-0">
            <b-row class="">

                <!-- 03 WEEK/MONTH/YEAR -->

                <b-col cols="12" md="7" xl="7" class="text-md-right mb-2 mb-md-0">
                     <div>
                          <b-button-group class="bg-white">
                             <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                                    Week
                             </b-button>
                             <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary">
                                    Month
                             </b-button>
                             <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary">
                                    Year
                            </b-button>
                          </b-button-group>
                     </div>
                </b-col>

                <!-- 04  This week -->
               
                <b-col cols="12" md="5" xl="5">
                     <b-form-group>
                          <v-select
                             class="bg-white"
                             placeholder="This Week: Feb 22 - Feb 28"
                             :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title3"
                            :options="option3"
                          />
                     </b-form-group>
                 </b-col>
             </b-row>
        </b-col>
      </b-row>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import {BAvatar, BBadge, BButtonGroup, BButton, BCard, BCol, BLink, BRow, BFormGroup} from 'bootstrap-vue'

export default {
   name: 'Home',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data() {
       
    return { 

       option1: [{title1: 'Programmes 01'},{title1: 'Programmes 02'},{title1: 'Programmes 03'}],

       option2: [ {title2:'United Kingdom'},{title2:'United States'},{title2:'Sri Lanka'},],

       option3: [{title3: 'Feb 15 - Feb 21'},{title3: 'Feb 8 - Feb 2'},{title3: 'Feb 1 - Feb 7'},{title3: 'Jan 25 - Jan 31'},],

    }
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
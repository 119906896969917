<template>
    <div class="home">
        <div><SearchBar /></div>

        
            <b-tabs >
                <b-tab title="Hours and Shifts">
                    <div class="Alert-body mt-n1 "><Hours /></div>
                </b-tab>
                <b-tab title="Cost">
                    <div class="Alert-body mt-n1"><Cost/> </div>
                </b-tab>
                <b-tab title="Staff">
                    <div class="Alert-body mt-n1"><Staff/></div>
                </b-tab>
            </b-tabs>

    </div>
    
</template>

<script>
import SearchBar from './searchbar'
import Hours from './hours/index'
import Cost from './cost/index'
import Staff from './staff/index'

import { BCard, BTab, BTabs } from 'bootstrap-vue'

export default {
    name:'Home',
    components:{
        SearchBar,
        Hours,
        Cost,
        Staff,
        BCard,
        BTab,
        BTabs,
    }
}
</script>
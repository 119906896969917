<template>
    <div class="home">

        <!-- Header Bar -->
             <div>
                 <b-card >
                    <b-row class=" mt-n2 ml-0">

          <b-col cols="12" class="pt-0 pb-0">
            <div class="row">

               <!-- 01 Total cost -->

                <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-primary" rounded>
                        <feather-icon icon="DollarSignIcon" size="18"/>
                    </b-avatar>

                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">£30,250.00</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-success font-weight-bold small">
                                 <feather-icon icon="ArrowUpIcon" />
                                 19%
                            </small>
                         </li>
                      </ul>
                           <small>Total Cost</small>
                    </div>
                </div>

               <!-- 02 Service Charge -->

                 <div class="d-flex align-items-center mr-5 mt-2">


                    <b-avatar variant="light-success" rounded >
                        <feather-icon icon="MapPinIcon" size="18" />
                    </b-avatar>
      
                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">£7,300.00</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-success font-weight-bold small">
                                 <feather-icon icon="ArrowUpIcon" />
                                 7%
                            </small>
                         </li>
                      </ul>
                           <small>Service Charge</small>
                    </div>
                </div>

                <!-- 03 Staff Payment -->


                 <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-success" rounded >
                        <feather-icon icon="UsersIcon" size="18" />
                    </b-avatar>
      
                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">£11,650.00</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-success font-weight-bold small">
                                 <feather-icon icon="ArrowUpIcon" />
                                 12%
                            </small>
                         </li>
                      </ul>
                           <small>Staff Payment</small>
                    </div>
                </div>


                <!-- 04 Total Paid -->

                 <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-success" rounded >
                       <feather-icon icon="CreditCardIcon" size="18" />
                    </b-avatar>

                    <div class="ml-1">
                        <h4 class="mb-0 font-weight-bolder">£18,950.00 </h4>
                        <small>Total Paid</small>
                    </div>
                </div>

                 <!-- 05 Total Unpaid -->
                
                 <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-danger" rounded >
                       <feather-icon icon="CreditCardIcon" size="18" />
                    </b-avatar>

                    <div class="ml-1">
                        <h4 class="mb-0 font-weight-bolder">£11,300.00 </h4>
                        <small>Total Unpaid</small>
                    </div>
                </div>


            </div>
             
          </b-col>

      </b-row>
      </b-card>
             </div>

             
              <!-- Charts -->

             <div>
               <b-row class="match-height">
                 <b-col lg="7">
                     <b-card>
                      <Chart/>
                     </b-card>
                 </b-col>
                 <b-col lg="5">
                     <b-card>
                      <Details/>
                     </b-card>
                 </b-col>
               </b-row>
             </div>

</div>
</template>


<script>
import Chart from './chart'
import Details from './details'
import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow} from 'bootstrap-vue'

export default {
  components: {
   Chart,
   Details,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
  },
  data() {
    return { 
    }
  },
}
</script>

<style scoped>

</style>

<template>
 <div>

   
    <b-card-header class="mt-n2">
       <div class="d-flex align-items-center ">

                <div>
                  <h3 class="mb-0 font-weight-bolder "> Cost </h3>
                </div>
                </div>
      
                <div class="ml-auto ">
                 <b-row>
                   <div class="ml-1">
                    <b-avatar variant="primary" square size="15" >
                        <feather-icon icon="FileIcon" size="1"/>
                    </b-avatar>
                   </div>

                   <div class="ml-1">
                     <small>Service Charge</small>
                    </div>

                    <div class="ml-1">
                     <b-avatar variant="success" square size="15">
                        <feather-icon icon="FileIcon" size="1"/>
                    </b-avatar>
                    </div>
                   <div class="ml-1">
                     <small>Staff Payments</small>
                    </div>
                    
                 </b-row>
                </div>
      <b-card-text class="font-medium-5 mb-0 ml-3">
        
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

  <div >
    <app-echart-bar
      :option-data="option"
    />
  </div>
 </div>
</template>

<script>
import { BAvatar, BBadge, BAlert, BButton, BCard, BCol, BLink, BRow, BCardHeader, BCardText } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'

export default {
  components: {
    AppEchartBar,
    BAvatar, 
    BBadge, 
    BAlert, 
    BButton, 
    BCard, 
    BCol, 
    BLink, 
    BRow, 
    BCardHeader,
    BCardText,
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ],
            splitLine: {show:false},
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: true },
          },
        ],
        grid: {
          left: '40px',
          right: '0px',
          bottom: '20px',
        },
        series: [
          {
            name: 'Service Charge',
            type: 'bar',
            stack: 'advertising',
            data: [900, 1700, 1800, 1100, 1600, 800, 800],
            barMaxWidth: '20%',
            barMinWidth: '10px',
             color: ["#1B9AAA"],
          },
          {
            name: 'Staff Payment',
            type: 'bar',
            stack: 'advertising',
            data: [800, 900, 1600, 1600, 1300, 900, 500],
            barMaxWidth: '20%',
            barMinWidth: '10px',
            itemStyle: {
            barBorderRadius: [10, 10, 0, 0],
            color: [ "#00C49A"],
            },
          },
        ],
      },
    }
  },
}
</script>

<template>
    <div class="home">

        <!-- Header Bar -->
             <div>
                 <b-card >
                    <b-row class=" mt-n2 ml-0">

          <b-col cols="12" class="pt-0 pb-0">
            <div class="row">

               <!-- 01 Total Shifts -->

                <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-primary" rounded>
                        <feather-icon icon="CalendarIcon" size="18"/>
                    </b-avatar>

                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">223</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-success font-weight-bold small">
                                 <feather-icon icon="ArrowUpIcon" />
                                 28%
                            </small>
                         </li>
                      </ul>
                           <small>Total Shifts</small>
                    </div>
                </div>

               <!-- 02 Filed Shifts -->

                 <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-success" rounded >
                        <feather-icon icon="CheckIcon" size="18" />
                    </b-avatar>

                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">116</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-success font-weight-bold small">
                                 <feather-icon icon="ArrowUpIcon" />
                                 14%
                            </small>
                         </li>
                      </ul>
                           <small>Filled Shifts</small>
                    </div>
                </div>

                <!-- 03 Target Hours -->

                 <div class="d-flex align-items-center mr-5 mt-2">

                    <b-avatar variant="light-warning" rounded >
                       <feather-icon icon="LoaderIcon" size="18" />
                    </b-avatar>

                    <div class="ml-1">
                        <h4 class="mb-0 font-weight-bolder">220 </h4>
                        <small>Target Hourse</small>
                    </div>
                </div>

                <!-- 04 Completed Hourse -->

                 <div class="d-flex align-items-center mr-5 mt-2">

                <b-avatar variant="light-success" rounded >
                        <feather-icon icon="CheckIcon" size="18" />
                    </b-avatar>

                    <div class="ml-1">
                      <ul class="list-inline mb-0">
                         <li class="list-inline-item">
                             <h4 class="mb-0 font-weight-bolder">167</h4>
                         </li>
                         <li class="list-inline-item">
                            <small class="text-success font-weight-bold small">
                                 <feather-icon icon="ArrowUpIcon" />
                                 12%
                            </small>
                         </li>
                      </ul>
                           <small>Completed Hourse</small>
                    </div>
                </div>
            </div>

          </b-col>

      </b-row>
      </b-card>
             </div>

              <!-- Charts -->

             <div>
               <b-row class="match-height">
                 <b-col lg="7">
                   <b-card>
                      <ShiftsStafftype />
                   </b-card>
                 </b-col>
                 <b-col lg="5">
                      <ShiftsStatus/>
                 </b-col>
               </b-row>
             </div>

              <div>
               <b-row>
                 <b-col lg="7">
                     <ClinicalHourse/>
                 </b-col>
                 <b-col lg="5">
                     <TargetClinicalHourse />
                 </b-col>
               </b-row>
             </div>
</div>
</template>


<script>

import ShiftsStafftype from './shift-stafftype'
import ShiftsStatus from './shift-status'
import ClinicalHourse from './clinicalhourse'
import TargetClinicalHourse from './taget-clinical-hourse'
import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow} from 'bootstrap-vue'

export default {
  components: {
    ShiftsStafftype,
    ShiftsStatus,
    ClinicalHourse,
    TargetClinicalHourse,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
